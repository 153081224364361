<template>
  <div id="navbar">
    <!-- 内容区域 -->
    <Content />
    <!--底部-->
    <Foot />
  </div>
</template>

<script>
import Foot from '@/components/Foot.vue'
import Content from './components/Content.vue'
export default {
  name: 'Product',
  components: {
    Foot,
    Content
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.img {
  width: 6.93rem;
  height: 4.06rem;
  display: block;
  margin: auto;
  padding: 0.4rem 0;
}
</style>
