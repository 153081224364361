<template>
  <!--底部 -->
  <div>
    <div id="foot">
      <div class="one">
        <div class="one-text">
          <div class="lianxi">联系方式</div>
          <div class="youxiang">公司电话：0351-7776777 </div>
          <div class="youxiang" >公司邮箱：18803517272@139.com </div>
          <div class="lianxi">联系地址</div>
        </div>
        <div><img class="ewm-img" src="../assets/images/ewm.png" alt="" /></div>
      </div>
      <div class="youxiang">地址：山西省太原市小店区南内环街华邦国际</div>
      <!-- <div>
        <div class="youqing">友情链接</div>
        <div class="yewu">
          <div>蓝鲸自动化运维</div>
          <div>机器视觉</div>
          <div>虚拟私有云VPC</div>
          <div>Docker容器管理</div>
          <div>借贷反欺诈AF</div>
          <div>微服务</div>
          <div>容器云平台</div>
          <div>智能客服</div>
        </div>
      </div> -->
    </div>
    <div @click="toRescue" class="biaozhu">
      版权所有 2022-<span>{{ systemDate }}</span>
      山西中天云网科技有限公司 晋ICP备2023016129号
    </div>
  </div>
</template>

<script>
export default {
  name: 'Foot',
  data() {
    return {
      systemDate: '',
      url: 'https://beian.miit.gov.cn/'
    }
  },
  mounted() {
    this.addDate()
  },
  methods: {
    addDate() {
      let nowDate = new Date()
      let date = {
        year: nowDate.getFullYear()
      }
      console.log(date)
      this.systemDate = date.year
    },
    toRescue() {
      window.location.href = this.url
    }
  }
}
</script>

<style lang="scss" scoped>
#foot {
  background: #072f51;
  color: #fff;
  padding: 0.5rem 0.3rem;
  margin: auto;
}
.one {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.34rem;
  font-weight: 400;
}
.lianxi {
  line-height: 0.7rem;
  font-weight: 600;
}
.youxiang {
  font-size: 0.3rem;
  font-weight: 400;
  color: #eeeeee;
  line-height: 0.7rem;
}
.ewm-img {
  width: 1.6rem;
  height: 1.57rem;
}
.dizhi {
  padding: 0.2rem;
  font-size: 0.3rem;
}
// .youqing {
//   font-size: 0.34rem;
//   font-weight: 400;
//   color: #ffffff;
//   padding-bottom: 0.4rem;
// }
// .yewu {
//   display: flex;
//   flex-wrap: wrap;
// }
// .yewu > div {
//   text-align: left;
//   width: 50%;
//   color: #ccc;
//   font-size: 0.26rem;
//   line-height: 0.58rem;
// }
.biaozhu {
  height: 0.79rem;
  background: #f4f4f4;
  font-size: 0.26rem;
  font-weight: 400;
  color: #313131;
  text-align: center;
  line-height: 0.79rem;
}
</style>
