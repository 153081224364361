<template>
  <div class="bbox">
    <div class="jiejue-box"><span class="jj-text">联系我们</span></div>
    <div class="cenyer">
      <div class="tit">山西中天云网科技有限公司</div>
      <div class="box">
        <img class="dizhi-img" src="@/assets/images/dh.png" alt="" />
        <div class="spa">公司电话：0351-7776777</div>
      </div>
      <div class="box">
        <img class="dizhi-img" src="@/assets/images/youxiang.png" alt="" />
        <div class="spa">公司邮箱：18803517272@139.com</div>
      </div>
      <div class="box">
        <img class="dizhi-img dz-img" src="@/assets/images/dizhi.png" alt="" />
        <div class="spa">公司地址：山西省太原市小店区南内环街华邦国际</div>
      </div>
      <div class="weizhi-box">
        <img class="weizhi" src="@/assets/images/weihzi.png" alt="" />
      </div>
      <!-- <div class="one">
        <div>
          <div class="one-img-box">
            <img src="@/assets/images/hezuo.png" alt="" />
          </div>
          <div>商务合作</div>
        </div>
        <div class="xian"></div>
        <div class="fler">
          <div class="youx">18803517272@139.com</div>
          <div class="yx-text">专业业务咨询，合作共赢</div>
        </div>
      </div> -->
      <!-- <div class="one">
        <div>
          <div class="one-img-box">
            <img src="@/assets/images/wx.png" alt="" />
          </div>
          <div>公众号</div>
        </div>
        <div class="xian"></div>
        <div class="fler last-div">
          <div class="last-tetx">
            <div class="youx">详细资讯</div>
            <div class="yx-text">尽在掌握</div>
          </div>
          <img class="last-img" src="@/assets/images/ewm.png" alt="" />
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.cenyer {
  padding: 0.3rem;
}
.jiejue-box {
  width: 100%;
  height: 4.2rem;
  margin: auto;
  background: url(../../../assets/images/lianxi.png) no-repeat;
  background-size: 100% 100%;
  text-align: center;
  position: relative;
  margin: auto;
}
.jiejue-box::after {
  content: '';
  display: block;
  width: 100%;
  height: 4.2rem;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.28);
}
.jj-text {
  font-size: 0.6rem;
  font-weight: bold;
  color: #ffffff;
  line-height: 0.35rem;
  line-height: 4.2rem;
  position: relative;
  z-index: 10;
}
.tit {
  font-size: 0.5rem;
  font-family: Microsoft YaHei;
  font-weight: 600;
  color: #333333;
  padding: 0.3rem;
}
.box {
  padding: 0 0.3rem;
  display: flex;
  align-items: center;
  margin: 0.2rem 0;
}
.dizhi-img {
  width: 0.4rem;
  height: auto;
  vertical-align: middle;
  padding: 0.1rem 0.3rem 0.1rem 0;
}
.dz-img {
  width: 0.37rem;
  height: 0.43rem;
}
.spa {
  font-size: 0.34rem;
  font-weight: 400;
  color: #333333;
}
.weizhi-box {
  padding: 0 0.3rem;
}
.weizhi {
  width: 100%;
  height: 4rem;
  display: block;
  margin: 0.45rem auto;
}
.one {
  display: flex;
  border: 0.03rem solid #666666;
  padding: 0.34rem;
  margin: 0.3rem;
  text-align: center;
  align-items: center;
}
.one img {
  width: 0.78rem;
  height: 0.68rem;
  padding: 0.1rem 0.4rem;
}
.one-img-box {
}
.xian {
  width: 0.02rem;
  height: 1rem;
  background: #666666;
}
.fler {
  flex: 1;
}
.last-div {
  display: flex;
}
.youx {
  font-size: 0.34rem;
  font-family: Arial;
  font-weight: bold;
  color: #333333;
  line-height: 0.39rem;
}
.yx-text {
  font-size: 0.26rem;
  font-weight: 400;
  color: #666666;
  line-height: 0.35rem;
}
.one .last-img {
  width: 1.27rem;
  height: 1.25rem;
  padding: 0;
}
.one .last-tetx {
  flex: 1;
  text-align: right;
  color: #666;
  padding-right: 0.2rem;
  font-size: 0.26rem;
}
.one .last-tetx .youx {
  font-size: 0.26rem;
  color: #666;
}
</style>
